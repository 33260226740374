@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';

/*
	TXT by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Basic */

	.image {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');

		&:before {
			display: none;
		}

		img {
			position: relative;
			-ms-behavior: url('assets/js/ie/PIE.htc');
		}
	}

/* Form */

	form {
		input[type="text"],
		input[type="email"],
		input[type="password"],
		select,
		textarea {
			position: relative;
			-ms-behavior: url('assets/js/ie/PIE.htc');
		}
	}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
	}

/* List */

	ul {
		&.contact {
			li {
				a {
					position: relative;
					-ms-behavior: url('assets/js/ie/PIE.htc');
				}
			}
		}

		&.special {
			a {
				position: relative;
				-ms-behavior: url('assets/js/ie/PIE.htc');
			}
		}
	}

/* Copyright  */

	#copyright {
		position: relative;
		-ms-behavior: url('assets/js/ie/PIE.htc');
	}

/* Nav */

	#nav {
		li {
			a, span {
				position: relative;
				-ms-behavior: url('assets/js/ie/PIE.htc');
			}
		}
	}

/* Section/Article */

	section, article {
		> .last-child,
		&.last-child {
			margin-bottom: 0;
		}
	}

/* Wrappers */

	#banner-wrapper {
		background-image: url('../images/banner.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		-ms-behavior: url('assets/js/ie/backgroundsize.min.htc');

		&:before {
			display: none;
		}
	}

/* Nav */

	#nav {
		background: #f8f8f8;

		&:after {
			display: none;
		}
	}

	.dropotron {
		box-shadow: none !important;
		-ms-behavior: url('assets/js/ie/PIE.htc');
	}

/* Banner */

	#banner {
		background: rgb(21, 28, 23);

		&:before {
			display: none;
		}
	}